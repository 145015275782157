import React, { useEffect } from "react";
import { Row, Col, CarouselCaption } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { Fade } from "react-awesome-reveal";
import Button from "react-bootstrap/Button";
import { useContraste } from "../../contexts/ContrasteContext";

import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import "./MinaElChiflon.css";
import importImages from "../../utils/importImages";
//import audio1 from "../../assets/audio/audio-chiflon/chiflon-intervencion-1.mp3";
//import audio2 from "../../assets/audio/audio-chiflon/chiflon-historia-1.mp3";
import ImageWithSpinner from "../../components/ImageWithSpinner/ImageWithSpinner";
// import imgChiflon1 from "../../assets/img/chiflon-1.Webp";
// import imgChiflon2 from "../../assets/img/chiflon-2.Webp";
// import imgChiflon3 from "../../assets/img/chiflon-3.Webp";
// import imgChiflon4 from "../../assets/img/chiflon-4.Webp";
// import imgChiflon5 from "../../assets/img/chiflon-5.Webp";
// import imgChiflon6 from "../../assets/img/chiflon-6.Webp";
// import imgChiflon7 from "../../assets/img/chiflon-7.Webp";
// import imgChiflon8 from "../../assets/img/chiflon-8.Webp";
// import imgChiflon9 from "../../assets/img/chiflon-9.Webp";
// import imgChiflon10 from "../../assets/img/chiflon-10.Webp";
// import imgChiflon11 from "../../assets/img/chiflon-11.Webp";
// import imgChiflon12 from "../../assets/img/chiflon-12.Webp";
// import imgChiflon13 from "../../assets/img/chiflon-13.Webp";
// import imgChiflon14 from "../../assets/img/chiflon-14.Webp";
// import imgChiflon15 from "../../assets/img/chiflon-15.Webp";
// import imgChiflon16 from "../../assets/img/chiflon-16.Webp";
// import imgChiflon17 from "../../assets/img/chiflon-17.Webp";
// import imgChiflon18 from "../../assets/img/chiflon-18.Webp";
// import imgChiflon19 from "../../assets/img/chiflon-19.Webp";
import vidChiflon1 from "../../assets/videos/video-chiflon-1.mp4";
import mapChiflon from "../../assets/pdf/plano_chiflon.pdf";
import { Carousel } from "react-bootstrap";
// Translations
import { useTranslation } from "react-i18next";
import SEO from "../../components/Seo/SEO";

const imagesChiflon = importImages.importImagesChiflon();

function ModalInterview(props) {
  const [t] = useTranslation("global");
  const { contrasteActivado } = useContraste();

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={contrasteActivado ? "modal-alto-contraste" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("chiflonPage.interview-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fade triggerOnce direction="up" easing="ease-in-out">
          <Card className="card-noeffect">
            <ImageWithSpinner
              className="image-main-modal"
              variant="top"
              src={imagesChiflon[`chiflon-8.Webp`]}
            />
            <Card.Text className="mb-2 text-muted text-center">
              {t("chiflonPage.interview-article.figure-1")}
            </Card.Text>
            <Card.Body>
              <Card.Title className="text-center">
                {t("chiflonPage.interview-article.title")}
              </Card.Title>
              <div>
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/wDppdxCPCBs?cc_load_policy=1"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
                <Card.Subtitle className="mb-2 text-center text-muted">
                  {t("chiflonPage.interview-article.subtitle-1")}
                </Card.Subtitle>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/OwAqFMRNtrQ?cc_load_policy=1"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
                <Card.Subtitle className="mb-2 text-center text-muted">
                  {t("chiflonPage.interview-article.subtitle-2")}
                </Card.Subtitle>
              </div>
            </Card.Body>
          </Card>
        </Fade>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="xl"
          variant="outline-primary"
          className="m-1"
          onClick={props.onHide}
        >
          {t("chiflonPage.history-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalIntervention(props) {
  const [t] = useTranslation("global");
  const { contrasteActivado } = useContraste();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={contrasteActivado ? "modal-alto-contraste" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("chiflonPage.intervention-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fade triggerOnce direction="up" easing="ease-in-out">
          <Card className="card-noeffect">
            <ImageWithSpinner
              className="image-main-modal"
              variant="top"
              src={imagesChiflon[`chiflon-4.Webp`]}
            />
            <Card.Text className="mb-2 text-muted text-center">
              {t("chiflonPage.intervention-article.figure-1")}
            </Card.Text>
            <Card.Body>
              <Card.Title className="text-center">
                {t("chiflonPage.intervention-article.title")}
              </Card.Title>
              <Card.Text>
                {t("chiflonPage.intervention-article.decree-1")}
                <a
                  href="https://www.monumentos.gob.cl/servicios/decretos/373_2009"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("chiflonPage.intervention-article.decree-2")}
                </a>
              </Card.Text>
              <Card.Text>
                {t("chiflonPage.intervention-article.category")}
              </Card.Text>
              <Card.Subtitle className="mb-2 text-muted">
                {t("chiflonPage.intervention-article.subtitle-1")}
              </Card.Subtitle>
              <Card.Text>
                {t("chiflonPage.intervention-article.owner")}
              </Card.Text>
              <Card.Subtitle className="mb-2 text-muted">
                {t("chiflonPage.intervention-article.subtitle-2")}
              </Card.Subtitle>
              <ListGroup>
                <ListGroup.Item>
                  {t("chiflonPage.intervention-article.list-1")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("chiflonPage.intervention-article.list-2")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("chiflonPage.intervention-article.list-3")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("chiflonPage.intervention-article.list-4")}
                </ListGroup.Item>
                <ListGroup.Item>
                  {t("chiflonPage.intervention-article.list-5")}
                  <a
                    href={mapChiflon}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("chiflonPage.intervention-article.link-1")}
                  </a>
                </ListGroup.Item>
              </ListGroup>
              <div className="d-flex justify-content-center mb-3">
                <ReactAudioPlayer src={t("audio.chiflon-intervencion")} controls />
              </div>
              <Card.Subtitle className="mb-2 text-muted">
                {t("chiflonPage.intervention-article.subtitle-3")}
              </Card.Subtitle>
              <Card.Text>
                {t("chiflonPage.intervention-article.paraph-1")}
              </Card.Text>
              <Card.Text>
                {t("chiflonPage.intervention-article.paraph-2")}
              </Card.Text>
              <Card.Text>
                {t("chiflonPage.intervention-article.paraph-3")}
              </Card.Text>
              <Card.Text>
                {t("chiflonPage.intervention-article.paraph-4")}
              </Card.Text>
              <Card.Text>
                {t("chiflonPage.intervention-article.paraph-5")}
              </Card.Text>
            </Card.Body>
          </Card>
        </Fade>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="xl"
          variant="outline-primary"
          className="m-1"
          onClick={props.onHide}
        >
          {t("chiflonPage.history-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalHistory(props) {
  const [t] = useTranslation("global");
  const { contrasteActivado } = useContraste();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={contrasteActivado ? "modal-alto-contraste" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("chiflonPage.history-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fade triggerOnce direction="up" easing="ease-in-out">
          <Card className="card-noeffect">
            <Card.Img
              className="image-main-modal"
              variant="top"
              src={imagesChiflon[`chiflon-5.Webp`]}
            />
            <Card.Text className="mb-2 text-muted text-center">
              {t("chiflonPage.history-article.figure-1")}
            </Card.Text>
            <div className="d-flex justify-content-center mb-3">
              <ReactAudioPlayer src={t("audio.chiflon-historia")} controls />
            </div>
            <Card.Body>
              <Card.Title className="text-center">
                {t("chiflonPage.history-article.title")}
              </Card.Title>
              <Card.Text>{t("chiflonPage.history-article.paraph-1")}</Card.Text>
              <div>
                <Row>
                  <Col xs={12} md={12} lg={8}>
                    <Card.Text>
                      {t("chiflonPage.history-article.paraph-2")}
                    </Card.Text>
                  </Col>
                  <Col xs={12} md={12} lg={4}>
                    <Image
                      fluid
                      variant="top"
                      src={imagesChiflon[`chiflon-6.Webp`]}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col xs={12} md={12} lg={4}>
                    <Image
                      fluid
                      variant="top"
                      src={imagesChiflon[`chiflon-7.Webp`]}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={8}>
                    <Card.Text>
                      {t("chiflonPage.history-article.paraph-3")}
                    </Card.Text>
                  </Col>
                </Row>
              </div>
              <Card.Text>{t("chiflonPage.history-article.paraph-4")}</Card.Text>
            </Card.Body>
          </Card>
        </Fade>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="xl"
          variant="outline-primary"
          className="m-1"
          onClick={props.onHide}
        >
          {t("chiflonPage.history-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function MinaElChiflon() {
  const [t] = useTranslation("global");
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <SEO
        title={t("chiflonPage.title1")}
        description={t("chiflonPage.description")}
        name="CEIA LOTA"
        type={t("chiflonPage.heritage")}
      />
      <Row>
        <Col xs={12} md={12}>
          <Row className="mb-1">
            <Col>
              <div className="full-width">
                <Carousel fade interval={5000}>
                  {[1, 2, 3].map((index) => (
                    <Carousel.Item className="carousel-item-first" key={index}>
                      <ImageWithSpinner
                        className="carousel-img-first h-100 w-100 object-cover object-center"
                        src={imagesChiflon[`chiflon-${index}.Webp`]}
                        alt={`Slide ${index}`}
                      />
                      <Carousel.Caption>
                        <h1>{t(`chiflonPage.chiflon-mine`)}</h1>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </Col>
          </Row>
          <Row>
          <Col md={12} lg={4} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <ImageWithSpinner
                    className="img-card"
                    variant="top"
                    src={imagesChiflon[`chiflon-5.Webp`]}
                  />
                  <Card.Text className="mb-2 text-muted">
                    {t("chiflonPage.history-article.figure-1")}
                  </Card.Text>
                  <Card.Body>
                    <Card.Title>
                      {t("chiflonPage.history-article.title")}
                    </Card.Title>
                    <Card.Text style={{ textAlign: "justify" }}>
                      {t("chiflonPage.history-article.paraph-preview")}
                    </Card.Text>

                    <Button
                      variant="outline-primary"
                      onClick={() => setModalShow2(true)}
                    >
                      {t("chiflonPage.history-article.view-more")}
                    </Button>
                    <ModalHistory
                      show={modalShow2}
                      onHide={() => setModalShow2(false)}
                    />
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            <Col md={12} lg={4} className="mb-1">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <ImageWithSpinner
                    className="img-card"
                    variant="top"
                    src={imagesChiflon[`chiflon-4.Webp`]}
                  />
                  <Card.Text className="mb-2 text-muted">
                    {t("chiflonPage.intervention-article.figure-1")}
                  </Card.Text>
                  <Card.Body>
                    <Card.Title>
                      {t("chiflonPage.intervention-article.title")}
                    </Card.Title>
                    <Card.Text>
                      {t("chiflonPage.intervention-article.decree-1")}
                      <a
                        href="https://www.monumentos.gob.cl/servicios/decretos/373_2009"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("chiflonPage.intervention-article.decree-2")}
                      </a>
                    </Card.Text>
                    <Card.Text>
                      {t("chiflonPage.intervention-article.category")}
                    </Card.Text>
                    <Card.Subtitle className="mb-2 text-muted">
                      {t("chiflonPage.intervention-article.subtitle-1")}
                    </Card.Subtitle>
                    <Card.Text>
                      {t("chiflonPage.intervention-article.owner")}
                    </Card.Text>
                    <Button
                      variant="outline-primary"
                      onClick={() => setModalShow1(true)}
                    >
                      {t("chiflonPage.intervention-article.view-more")}
                    </Button>
                    <ModalIntervention
                      show={modalShow1}
                      onHide={() => setModalShow1(false)}
                    />
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            <Col md={12} lg={4} className="mb-1">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <ImageWithSpinner
                    className="img-card"
                    variant="top"
                    src={imagesChiflon[`chiflon-8.Webp`]}
                  />
                  <Card.Text className="mb-2 text-muted">
                    {t("chiflonPage.interview-article.figure-1")}
                  </Card.Text>
                  <Card.Body>
                    <Card.Title>
                      {t("chiflonPage.interview-article.title")}
                    </Card.Title>

                    <Card.Text>
                      <iframe
                        width="100%"
                        height="250"
                        src="https://www.youtube.com/embed/wDppdxCPCBs?cc_load_policy=1"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    </Card.Text>
                    <Card.Subtitle className="mb-2 text-muted">
                      {t("chiflonPage.interview-article.subtitle-1")}
                    </Card.Subtitle>

                    <Button
                      variant="outline-primary"
                      onClick={() => setModalShow3(true)}
                    >
                      {t("chiflonPage.interview-article.view-more")}
                    </Button>
                    <ModalInterview
                      show={modalShow3}
                      onHide={() => setModalShow3(false)}
                    />
                  </Card.Body>
                </Card>
              </Fade>
            </Col>

            {/* Visita pedagógica 2023 */}
            <Col md={12} lg={4} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Body>
                    <Card.Title>{t("chiflonPage.visit.title")}</Card.Title>
                    <Carousel fade>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="carousel-img d-block w-100"
                          src={imagesChiflon[`chiflon-14.Webp`]}
                          alt={t("chiflonPage.visit.visit-1")}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="carousel-img d-block w-100"
                          src={imagesChiflon[`chiflon-15.Webp`]}
                          alt={t("chiflonPage.visit.visit-1")}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="carousel-img d-block w-100"
                          src={imagesChiflon[`chiflon-16.Webp`]}
                          alt={t("chiflonPage.visit.visit-1")}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="carousel-img d-block w-100"
                          src={imagesChiflon[`chiflon-17.Webp`]}
                          alt={t("chiflonPage.visit.visit-1")}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="carousel-img d-block w-100"
                          src={imagesChiflon[`chiflon-18.Webp`]}
                          alt={t("chiflonPage.visit.visit-1")}
                        />
                      </Carousel.Item>
                    </Carousel>
                    <Card.Text>{t("chiflonPage.visit.visit-1")}</Card.Text>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            {/* Dibujos a carbon 2023 */}
            <Col md={12} lg={4} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Body>
                    <Card.Title>
                      {t("chiflonPage.charcoal-drawings.title")}
                    </Card.Title>
                    <Carousel fade>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="carousel-img d-block w-100"
                          src={imagesChiflon[`chiflon-19.Webp`]}
                          alt={t("chiflonPage.charcoal-drawings.draw-1")}
                        />
                      </Carousel.Item>
                    </Carousel>
                    <Card.Text>
                      {t("chiflonPage.charcoal-drawings.draw-1")}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            {/* Tuberculosis */}
            <Col md={12} lg={4} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Body>
                    <Card.Title>
                      {t("chiflonPage.miners-diseases.title")}
                    </Card.Title>
                    <ReactPlayer
                      url={vidChiflon1}
                      controls={true}
                      width="100%"
                      height="400"
                      title={t("chiflonPage.miners-diseases.title")}
                    />
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            {/* Dibujos 2022 */}
            <Col md={12} lg={6} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Body>
                    <Card.Title>{t("chiflonPage.drawings.title")}</Card.Title>
                    <Carousel fade>
                      <Carousel.Item className="carousel-item-draw">
                        <ImageWithSpinner
                          className="carousel-img-draw d-block w-100"
                          src={imagesChiflon[`chiflon-9.Webp`]}
                          alt={t("chiflonPage.drawings.draw-1")}
                        />
                        <CarouselCaption className="carousel-caption-draw">
                          {t("chiflonPage.drawings.draw-1")}
                        </CarouselCaption>
                      </Carousel.Item>
                      <Carousel.Item className="carousel-item-draw">
                        <ImageWithSpinner
                          className="carousel-img-draw d-block w-100"
                          src={imagesChiflon[`chiflon-10.Webp`]}
                          alt={t("chiflonPage.drawings.draw-5")}
                        />
                        <CarouselCaption className="carousel-caption-draw">
                          {t("chiflonPage.drawings.draw-5")}
                        </CarouselCaption>
                      </Carousel.Item>
                      <Carousel.Item className="carousel-item-draw">
                        <ImageWithSpinner
                          className="carousel-img-draw d-block w-100"
                          src={imagesChiflon[`chiflon-11.Webp`]}
                          alt={t("chiflonPage.drawings.draw-4")}
                        />
                        <CarouselCaption className="carousel-caption-draw">
                          {t("chiflonPage.drawings.draw-4")}
                        </CarouselCaption>
                      </Carousel.Item>
                      <Carousel.Item className="carousel-item-draw">
                        <ImageWithSpinner
                          className="carousel-img-draw d-block w-100"
                          src={imagesChiflon[`chiflon-12.Webp`]}
                          alt={t("chiflonPage.drawings.draw-3")}
                        />
                        <CarouselCaption className="carousel-caption-draw">
                          {t("chiflonPage.drawings.draw-3")}
                        </CarouselCaption>
                      </Carousel.Item>
                      <Carousel.Item className="carousel-item-draw">
                        <ImageWithSpinner
                          className="carousel-img-draw d-block w-100"
                          src={imagesChiflon[`chiflon-13.Webp`]}
                          alt={t("chiflonPage.drawings.draw-2")}
                        />
                        <CarouselCaption className="carousel-caption-draw">
                          {t("chiflonPage.drawings.draw-2")}
                        </CarouselCaption>
                      </Carousel.Item>
                    </Carousel>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            {/* Videos Dron */}
            <Col md={12} lg={6} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Body>
                    <Card.Title>
                      {t("chiflonPage.drawings.video-dron")}
                    </Card.Title>
                    <Carousel fade>
                      <Carousel.Item>
                        <iframe
                          width="100%"
                          height="400"
                          src="https://www.youtube.com/embed/nOaVkn7RJl8"
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                      </Carousel.Item>
                      <Carousel.Item>
                        <iframe
                          width="100%"
                          height="400"
                          src="https://www.youtube.com/embed/JVBBLiv_Q1c"
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                      </Carousel.Item>
                      <Carousel.Item>
                        <iframe
                          width="100%"
                          height="400"
                          src="https://www.youtube.com/embed/GJcgxOyg3Fs"
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                      </Carousel.Item>
                    </Carousel>
                  </Card.Body>
                </Card>
              </Fade>
              <Card></Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31704.432798472815!2d-73.17597865824015!3d-37.079550994121696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9669c195d910b51d%3A0x63219e958ec4799f!2sMina%20Chifl%C3%B3n%20del%20Diablo!5e0!3m2!1ses-419!2scl!4v1715557930507!5m2!1ses-419!2scl&quot"
        style={{ border: 0 }}
        title="Ubicación de la Mina El Chiflón del Diablo"
        width="100%"
        height="400"
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default MinaElChiflon;
