import React, { useEffect } from "react";
import "./ParqueLota.css";
import { useContraste } from "../../contexts/ContrasteContext";

import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { Fade } from "react-awesome-reveal";
import Button from "react-bootstrap/Button";

import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
//import audio1 from "../../assets/audio/audio-parque/parque-intervencion-1.mp3";
import ReactAudioPlayer from "react-audio-player";
import ImageWithSpinner from "../../components/ImageWithSpinner/ImageWithSpinner";

import importImages from "../../utils/importImages";
// import imgPark1 from "../../assets/img/parque-lota-1.Webp";
// import imgPark2 from "../../assets/img/parque-lota-2.Webp";
// import imgPark3 from "../../assets/img/parque-lota-3.Webp";
// import imgPark4 from "../../assets/img/parque-lota-4.Webp";
// import imgPark5 from "../../assets/img/parque-lota-5.Webp";
// import imgPark6 from "../../assets/img/parque-lota-6.Webp";
// import imgPark8 from "../../assets/img/parque-lota-7.Webp";
// import imgPark7 from "../../assets/img/parque-lota-8.Webp";
// import imgPark9 from "../../assets/img/parque-lota-9.Webp";
// import imgPark10 from "../../assets/img/parque-inauguracion-1.Webp";
// import imgPark11 from "../../assets/img/parque-visita-1.Webp";
// import imgPark12 from "../../assets/img/parque-visita-2.Webp";
// import imgPark13 from "../../assets/img/parque-visita-3.Webp";
// import imgPark14 from "../../assets/img/parque-visita-4.Webp";
// import imgPark15 from "../../assets/img/parque-escultura-1.Webp";

import pdfPark1 from "../../assets/pdf/ficha-botanica-parque-1.pdf";
import pdfPark2 from "../../assets/pdf/ficha-botanica-parque-2.pdf";
import pdfPark3 from "../../assets/pdf/bitacora-1.pdf";
import pdfPark4 from "../../assets/pdf/bitacora-2.pdf";
import pdfPark5 from "../../assets/pdf/triptico-1.pdf";

import mapPark from "../../assets/pdf/plano_parque.pdf";
import { Carousel } from "react-bootstrap";
// Translations
import { useTranslation } from "react-i18next";
import SEO from "../../components/Seo/SEO";

const ImagesParque = importImages.importImagesParqueLota();

function ModalInterview(props) {
  const [t] = useTranslation("global");
  const { contrasteActivado } = useContraste();

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={contrasteActivado ? "modal-alto-contraste" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("parkPage.interview-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fade triggerOnce direction="up" easing="ease-in-out">
          <Card className="card-noeffect">
            <ImageWithSpinner
              className="image-main-modal"
              variant="top"
              src={ImagesParque[`parque-lota-5.Webp`]}
            />
            <Card.Text className="mb-2 text-muted text-center">
              {t("parkPage.interview-article.figure-1")}
            </Card.Text>
            <Card.Body>
              <Card.Title className="text-center">
                {t("parkPage.interview-article.title")}
              </Card.Title>
              <div>
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/cjp7sbbxlJ0"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
                <Card.Subtitle className="mb-2 text-center text-muted">
                  {t("parkPage.interview-article.subtitle-1")}
                </Card.Subtitle>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/yqkTha6V2o4"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
                <Card.Subtitle className="mb-2 text-center text-muted">
                  {t("parkPage.interview-article.subtitle-2")}
                </Card.Subtitle>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/RhcEO0YN6yU"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
                <Card.Subtitle className="mb-2 text-center text-muted">
                  {t("parkPage.interview-article.subtitle-3")}
                </Card.Subtitle>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/rrmbzZdGO-8"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
                <Card.Subtitle className="mb-2 text-center text-muted">
                  {t("parkPage.interview-article.subtitle-4")}
                </Card.Subtitle>
              </div>
            </Card.Body>
          </Card>
        </Fade>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          {t("parkPage.interview-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalIntervention(props) {
  const [t] = useTranslation("global");
  const { contrasteActivado } = useContraste();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={contrasteActivado ? "modal-alto-contraste" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("parkPage.intervention-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fade triggerOnce direction="up" easing="ease-in-out">
          <Card className="card-noeffect">
            <ImageWithSpinner
              className="image-main-modal"
              variant="top"
              src={ImagesParque[`parque-lota-4.Webp`]}
            />
            <Card.Text className="mb-2 text-muted text-center">
              {t("parkPage.intervention-article.figure-1")}
            </Card.Text>
            <Card.Body>
              <Card.Title className="text-center">
                {t("parkPage.intervention-article.title")}
              </Card.Title>
              <div>
                {t("parkPage.intervention-article.decree-1")}
                <a
                  href="https://www.monumentos.gob.cl/servicios/decretos/373_2009"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("parkPage.intervention-article.decree-2")}
                </a>
              </div>
              <Card.Text>
                {t("parkPage.intervention-article.category")}
              </Card.Text>
              <Card.Subtitle className="mb-2 text-muted">
                {t("parkPage.intervention-article.subtitle-1")}
              </Card.Subtitle>
              <Card.Text>{t("parkPage.intervention-article.owner")}</Card.Text>
              <Card.Subtitle className="mb-2 text-muted">
                {t("parkPage.intervention-article.subtitle-2")}
              </Card.Subtitle>
              <div>
                <ListGroup>
                  <ListGroup.Item>
                    {t("parkPage.intervention-article.list-1")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("parkPage.intervention-article.list-2")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("parkPage.intervention-article.list-3")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("parkPage.intervention-article.list-4")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("parkPage.intervention-article.list-5")}
                    <a href={mapPark} target="_blank" rel="noopener noreferrer">
                      {t("parkPage.intervention-article.link-1")}
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <div className="d-flex justify-content-center mb-3">
                <ReactAudioPlayer src={t("audio.parque-intervencion")} controls />
              </div>
              <Card.Subtitle className="mb-2 text-muted">
                {t("parkPage.intervention-article.subtitle-3")}
              </Card.Subtitle>
              <Card.Text>
                {t("parkPage.intervention-article.paraph-1")}
              </Card.Text>
              <Card.Text>
                {t("parkPage.intervention-article.paraph-2")}
              </Card.Text>
              <Card.Text>
                {t("parkPage.intervention-article.paraph-3")}
              </Card.Text>
            </Card.Body>
          </Card>
        </Fade>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          {t("parkPage.intervention-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalHistory(props) {
  const [t] = useTranslation("global");
  const { contrasteActivado } = useContraste();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={contrasteActivado ? "modal-alto-contraste" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("parkPage.history-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fade triggerOnce direction="up" easing="ease-in-out">
          <Card className="card-noeffect">
            <ImageWithSpinner
              className="image-main-modal"
              variant="top"
              src={ImagesParque[`parque-lota-9.Webp`]}
            />
            <Card.Text className="mb-2 text-muted text-center">
              {t("parkPage.history-article.figure-1")}
            </Card.Text>
            <Card.Body>
              <Card.Title className="text-center">
                {t("parkPage.history-article.title")}
              </Card.Title>

              <Card.Text>
                {t("parkPage.history-article.flora-1")}
                <a
                  href={ImagesParque[`parque-lota-6.Webp`]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("parkPage.history-article.link")}
                </a>
              </Card.Text>
              <Card.Text>
                {t("parkPage.history-article.flora-2")}
                <a
                  href={ImagesParque[`parque-lota-7.Webp`]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("parkPage.history-article.link")}
                </a>
              </Card.Text>
              <Card.Text>
                {t("parkPage.history-article.flora-3")}
                <a
                  href={ImagesParque[`parque-lota-8.Webp`]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("parkPage.history-article.link")}
                </a>
              </Card.Text>
              <Card.Text>
                {t("parkPage.history-article.flora-4")}
                <a href={pdfPark1} target="_blank" rel="noopener noreferrer">
                  {t("parkPage.history-article.link")}
                </a>
              </Card.Text>
              <Card.Text>
                {t("parkPage.history-article.flora-5")}
                <a href={pdfPark2} target="_blank" rel="noopener noreferrer">
                  {t("parkPage.history-article.link")}
                </a>
              </Card.Text>
              <Card.Text>
                {t("parkPage.history-article.by-description")}
              </Card.Text>
            </Card.Body>
          </Card>
        </Fade>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          {t("chiflonPage.history-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ParqueLota() {
  const [t] = useTranslation("global");
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <SEO
        title={t("parkPage.title1")}
        description={t("parkPage.description")}
        name="CEIA LOTA"
        type={t("parkPage.heritage")}
      />
      <Row>
        <Col xs={12} md={12}>
          <Row className="mb-1">
            <Col>
              <div className="full-width">
                <Carousel fade interval={5000}>
                  {[1, 2, 3].map((index) => (
                    <Carousel.Item key={index} className="carousel-item-first">
                      <ImageWithSpinner
                        className="carousel-img-first h-100 w-100 object-cover object-center"
                        src={ImagesParque[`parque-lota-${index}.Webp`]}
                        alt={`Slide ${index}`}
                      />
                      <Carousel.Caption>
                        <h1>{t("parkPage.title")}</h1>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={4} className="mb-1">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <ImageWithSpinner
                    className="img-card"
                    variant="top"
                    src={ImagesParque[`parque-lota-4.Webp`]}
                  />
                  <Card.Text className="mb-2 text-muted">
                    {t("parkPage.intervention-article.figure-1")}
                  </Card.Text>
                  <Card.Body>
                    <Card.Title>
                      {t("parkPage.intervention-article.title")}
                    </Card.Title>
                    <Card.Text>
                      {t("parkPage.intervention-article.decree-1")}
                      <a
                        href="https://www.monumentos.gob.cl/servicios/decretos/373_2009"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("parkPage.intervention-article.decree-2")}
                      </a>
                    </Card.Text>
                    <Card.Text>
                      {t("parkPage.intervention-article.category")}
                    </Card.Text>
                    <Card.Subtitle className="mb-2 text-muted">
                      {t("parkPage.intervention-article.subtitle-1")}
                    </Card.Subtitle>
                    <Card.Text>
                      {t("parkPage.intervention-article.owner")}
                    </Card.Text>
                    <Button
                      variant="outline-primary"
                      onClick={() => setModalShow1(true)}
                    >
                      {t("parkPage.intervention-article.view-more")}
                    </Button>
                    <ModalIntervention
                      show={modalShow1}
                      onHide={() => setModalShow1(false)}
                    />
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            <Col md={12} lg={4} className="mb-1">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <ImageWithSpinner
                    className="img-card"
                    variant="top"
                    src={ImagesParque[`parque-lota-5.Webp`]}
                  />
                  <Card.Text className="mb-2 text-muted">
                    {t("parkPage.interview-article.figure-1")}
                  </Card.Text>
                  <Card.Body>
                    <Card.Title>
                      {t("parkPage.interview-article.title")}
                    </Card.Title>

                    <Card.Text>
                      <iframe
                        width="100%"
                        height="250"
                        src="https://www.youtube.com/embed/cjp7sbbxlJ0"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    </Card.Text>
                    <Card.Subtitle className="mb-2 text-muted">
                      {t("parkPage.interview-article.subtitle-1")}
                    </Card.Subtitle>

                    <Button
                      variant="outline-primary"
                      onClick={() => setModalShow3(true)}
                    >
                      {t("parkPage.interview-article.view-more")}
                    </Button>
                    <ModalInterview
                      show={modalShow3}
                      onHide={() => setModalShow3(false)}
                    />
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            <Col md={12} lg={4} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <ImageWithSpinner
                    className="img-card"
                    variant="top"
                    src={ImagesParque[`parque-lota-9.Webp`]}
                  />
                  <Card.Text className="mb-2 text-muted">
                    {t("parkPage.history-article.figure-1")}
                  </Card.Text>
                  <Card.Body>
                    <Card.Title>
                      {t("parkPage.history-article.title")}
                    </Card.Title>
                    <Card.Text>
                      {t("parkPage.history-article.flora-1")}
                      <a
                        href={ImagesParque[`parque-lota-6.Webp`]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("parkPage.history-article.link")}
                      </a>
                    </Card.Text>
                    <Card.Text>
                      {t("parkPage.history-article.flora-2")}
                      <a
                        href={ImagesParque[`parque-lota-7.Webp`]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("parkPage.history-article.link")}
                      </a>
                    </Card.Text>
                    <Card.Text>
                      {t("parkPage.history-article.flora-3")}
                      <a
                        href={ImagesParque[`parque-lota-8.Webp`]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("parkPage.history-article.link")}
                      </a>
                    </Card.Text>
                    <Card.Text>
                      {t("parkPage.history-article.flora-4")}
                      <a
                        href={pdfPark1}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("parkPage.history-article.link")}
                      </a>
                    </Card.Text>
                    <Card.Text>
                      {t("parkPage.history-article.flora-5")}
                      <a
                        href={pdfPark2}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("parkPage.history-article.link")}
                      </a>
                    </Card.Text>
                    <Card.Text>
                      {t("parkPage.history-article.by-description")}
                    </Card.Text>

                    <Button
                      variant="outline-primary"
                      onClick={() => setModalShow2(true)}
                    >
                      {t("parkPage.history-article.view-more")}
                    </Button>
                    <ModalHistory
                      show={modalShow2}
                      onHide={() => setModalShow2(false)}
                    />
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            {/* Inauguración */}
            <Col md={12} lg={4} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Title>{t("parkPage.opening.title")}</Card.Title>
                  <Card.Body>
                    <ImageWithSpinner
                      className="d-block w-100"
                      src={ImagesParque[`parque-inauguracion-1.Webp`]}
                      alt={t("parkPage.opening.photo-1")}
                    />
                    <Card.Text>{t("parkPage.opening.photo-1")}</Card.Text>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            {/* Visita pedagógica */}
            <Col md={12} lg={4} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Body>
                    <Card.Title>{t("parkPage.visit.title")}</Card.Title>
                    <Carousel fade>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="img-card"
                          src={ImagesParque[`parque-visita-1.Webp`]}
                          alt={t("parkPage.visit.desc-1")}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="img-card"
                          src={ImagesParque[`parque-visita-2.Webp`]}
                          alt={t("parkPage.visit.desc-1")}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="img-card"
                          src={ImagesParque[`parque-visita-3.Webp`]}
                          alt={t("parkPage.visit.desc-1")}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="img-card"
                          src={ImagesParque[`parque-visita-4.Webp`]}
                          alt={t("parkPage.visit.desc-1")}
                        />
                      </Carousel.Item>
                    </Carousel>
                    <Card.Text>{t("parkPage.visit.desc-1")}</Card.Text>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            {/* Bitacora de Viaje */}
            <Col md={12} lg={4} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Body>
                    <Card.Title>{t("parkPage.travel-log.title")}</Card.Title>
                    <Card.Text>
                      {t("parkPage.travel-log.doc-1")}
                      <a
                        href={pdfPark3}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("parkPage.history-article.link")}
                      </a>
                    </Card.Text>
                    <Card.Text>
                      {t("parkPage.travel-log.doc-2")}
                      <a
                        href={pdfPark4}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("parkPage.history-article.link")}
                      </a>
                    </Card.Text>
                    <Card.Text>
                      {t("parkPage.travel-log.by-description")}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            {/* Triptico Inglés */}
            <Col md={12} lg={6} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Body>
                    <Card.Title>{t("parkPage.triptych.title")}</Card.Title>
                    <Card.Text>
                      {t("parkPage.triptych.triptych-1")}
                      <a
                        href={pdfPark5}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("parkPage.triptych.link")}
                      </a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            {/* Esculturas */}
            <Col md={12} lg={6} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Body>
                    <Card.Title>{t("parkPage.sculptures.title")}</Card.Title>
                    <Carousel fade>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="img-card"
                          src={ImagesParque[`parque-escultura-1.Webp`]}
                          alt={t("parkPage.sculptures.desc-1")}
                        />
                      </Carousel.Item>
                    </Carousel>
                    <Card.Text>{t("parkPage.sculptures.desc-1")}</Card.Text>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
          </Row>
        </Col>
      </Row>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3182.6265410517685!2d-73.1653089!3d-37.0902007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9669c1efd1da0381%3A0x887be368312383d1!2sParque%20Isidora%20Goyenechea%20de%20Cousi%C3%B1o%20de%20Lota!5e0!3m2!1ses-419!2scl!4v1715558087442!5m2!1ses-419!2scl&quot"
        style={{ border: 0 }}
        title="Ubicación del Parque Lota"
        width="100%"
        height="400"
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default ParqueLota;
