import React from "react";
import { useState } from "react";
import "./Footer.css";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import logoCeia from "../../assets/img/logo-ceia.png";
import importImages from "../../utils/importImages";
import Feedback from "../Feedback/Feedback";
//import { withTheme } from "styled-components";

const ImagesLogos = importImages.importImagesLogos();



function FeedbackModal({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Feedback />
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
}

function Footer() {
  const [t] = useTranslation("global");
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <footer className="page-footer font-small blue pt-4">
      <div className="container-fluid text-center text-md-left">
        <div className="row">
          <div className="col-md-6 mt-md-0 mt-3">
            <Row className="justify-content-md-center">
              <Col xs={12} md={6} className="mt-2">
                <a
                  href="http://www.ceialota.cl"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="logo-ceia"
                    variant="top"
                    src={ImagesLogos[`logo-ceia.Webp`]}
                  ></Image>
                </a>
              </Col>
              <Col xs={12} md={6} className="mt-2 ceia-info">
              <a  className="a-footer" href="https://www.google.com/maps/search/?api=1&query=Serrano+086,+Lota,+Región+del+Bío+Bío,+Chile" target="_blank"  rel="noopener noreferrer">Serrano 086, Lota, Región del Bío Bío, Chile</a>
                <a className="a-footer" href="tel:+56412347585"> +56412347585 </a>
                <a  className="a-footer" href="mailto:ceiamexicolota@gmail.com">ceiamexicolota@gmail.com</a>
                <Button  className= "button-feedback" variant="primary" onClick={handleShow}>
                  Envía tu Feedback
                </Button>

                {/* Uso del componente FeedbackModal */}
                <FeedbackModal show={showModal} handleClose={handleClose} />
              </Col>
            </Row>
          </div>

          <hr className="clearfix w-100 d-md-none pb-0" />

          <div className="links col-md-6 mb-md-0 mb-3">
            <h5 className="text-uppercase">{t("footer.heritage")}</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/iglesia-san-matias" className="custom-link-footer">
                  {t("footer.san-matias-church")}
                </Link>
              </li>
              <li>
                <Link to="/mina-el-chiflon" className="custom-link-footer">{t("footer.chiflon-mine")} </Link>
              </li>
              <li>
                <Link to="/parque-isidora-cousino" className="custom-link-footer">
                  {t("footer.isidora-cousino-park")}
                </Link>
              </li>
              <li>
                <Link to="/fuerte-colcura" className="custom-link-footer">{t("footer.colcura-fort")}</Link>
              </li>
              <li>
                <Link to="/gota-de-leche" className="custom-link-footer">{t("footer.gota-de-leche")}</Link>
              </li>
              <li>
                <Link to="https://www.monumentos.gob.cl/monumentos/monumentos-monumentos" className="custom-link-footer">
                  {t("footer.other-heritage")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-copyright text-center py-3">
        C.E.I.A LOTA San Luis de Potosí © 2023 {t("footer.design")}:{" "}
        <a
          href="https://ceialota.cl/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ceialota.cl
        </a>
      </div>
    </footer>
  );
}

export default Footer;
