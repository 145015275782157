import React  from 'react';
import { useRouteError, Link } from "react-router-dom";

export const NotFound = () => {

  const error = useRouteError();
  console.log(error);
  return (
    <div id="error-page">
      <h1>404</h1>
      <p>Page not found</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <Link to='/'>Volver al Home</Link>

    </div>
  )
}
