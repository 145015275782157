import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./LayoutPublic.css";
import ResponsiveAppBar from "../components/ResponsiveAppBar/ResponsiveAppBar";
import Footer from "../components/Footer/Footer";
import { Outlet } from "react-router-dom";
import LogoFooter from "../components/LogosFooter/LogoFooter";
import { HelmetProvider } from "react-helmet-async";
import { FloatButton } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useContraste } from "../contexts/ContrasteContext";

const LayoutPublic = () => {
  const { contrasteActivado, toggleContraste } = useContraste();
  
  // Estado para controlar si la AppBar es transparente o no
  const [isScrolled, setIsScrolled] = useState(false);

  // Efecto para escuchar el evento de scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true); // Cuando haga scroll hacia abajo más de 50px
      } else {
        setIsScrolled(false); // Cuando vuelva a la parte superior
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={contrasteActivado ? "alto-contraste" : ""}>
        <HelmetProvider>
          <div style={{ position: "sticky",  zIndex: "1000"}}>
            {/* Pasamos la clase basada en el estado */}
            <ResponsiveAppBar isScrolled={isScrolled} />
          </div>
          <main className="container-fluid h-100">
            <Outlet />
            <FloatButton
              onClick={toggleContraste}
              style={{
                left: 20,
                width: "60px", // Ancho del botón
                height: "60px", // Alto del botón
                fontSize: "30px", // Tamaño de fuente del icono
                zIndex: 9999,
              }}
              icon={<EyeOutlined />}
            />
            <FloatButton.BackTop />
          </main>
          <LogoFooter></LogoFooter>
          <Footer></Footer>
        </HelmetProvider>
      </div>
    </>
  );
};
export default LayoutPublic;
