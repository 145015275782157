import React, { createContext, useContext, useState } from "react";

const ContrasteContext = createContext();

export const useContraste = () => useContext(ContrasteContext);

export const ContrasteProvider = ({ children }) => {
  const [contrasteActivado, setContrasteActivado] = useState(false);

  const toggleContraste = () => {
    setContrasteActivado(!contrasteActivado);
  };

  return (
    <ContrasteContext.Provider value={{ contrasteActivado, toggleContraste }}>
      {children}
    </ContrasteContext.Provider>
  );
};
