import React from 'react';
import { Card, Input, Button, Form, message } from 'antd';
import emailjs from 'emailjs-com';
import './Feedback.css';
import offensiveWordsList from './es.json';

export default function FeedbackComponent() {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // Verificar si el comentario contiene palabras ofensivas
    const containsOffensiveWord = offensiveWordsList.some(word => values.message.toLowerCase().includes(word.toLowerCase()));

    if (containsOffensiveWord) {
      message.error('Tu comentario contiene palabras que no están permitidas.');
      return;
    }

    emailjs.send('service_qetw93n', 'template_u35eg3c', values, 'j4VfNzNmsUip-zP5I')
      .then((result) => {
        message.success('Mensaje enviado con éxito!');
        form.resetFields(); 
      }, (error) => {
        message.error('Error al enviar el mensaje. Por favor, inténtalo de nuevo.');
        console.log(error.text);
      });
  };

  return (
    <Card title="Feedback" bordered={false} className="feedback-card">
      <p>Comparte tus ideas para mejorar la página.</p>
      <Form layout="vertical" className="feedback-form" form={form} onFinish={onFinish}>
        <Form.Item
          label="Nombre"
          name="from_name"
          rules={[{ required: true, message: 'Por favor, ingresa tu nombre.' },
            { min: 2, message: 'El nombre debe contener al menos dos caracteres.' },
            { max: 50, message: 'El nombre no puede exceder los 50 caracteres.'},
            { pattern: /^[a-zA-Z\s]*$/, message: 'El nombre solo puede contener letras y espacios.'},
            { pattern: /^(?!\s)([a-zA-Z\s])/, message: 'El nombre no puede comenzar con un espacio.'},
            { pattern: /[a-zA-Z]+(?<!\s)$/, message: 'El nombre no puede terminar con un espacio.'},
            { pattern: /^(?!.*(.)\1{2,}).+$/, message: 'El nombre no puede contener más de dos letras iguales consecutivas.'}
          ]}
        >
          <Input placeholder="Ingresa tu nombre" />
        </Form.Item>
        <Form.Item
  label="Correo Electrónico"
  name="from_email"
  rules={[
    { required: true, message: 'Por favor, ingresa tu correo electrónico.' },
    { max: 50, message: 'El correo electrónico no puede exceder los 50 caracteres.'},
    {
      validator: (_, value) => {
        const trimmedValue = value.trim();
        if (!trimmedValue) {
          return Promise.resolve();
        }
        if (!/^\S+@\S+\.\S+$/.test(trimmedValue)) {
          return Promise.reject(new Error('El correo electrónico no es válido.'));
        }
        return Promise.resolve();
      }
    }
  ]}
>
<Input placeholder="Ingresa tu correo electrónico" />
</Form.Item>

        <Form.Item
          label="Comentarios"
          name="message"
          rules={[
            { required: true, message: 'Por favor, comparte tus comentarios o sugerencias.' },
            { min: 2, message: 'El comentario debe contener al menos dos letras.' },
            { max: 500, message: 'El comentario no puede exceder los 500 caracteres.'},
            { pattern: /^(?!\s)([a-zA-Z\s])/, message: 'El comentario no puede comenzar con un espacio.'},
            { pattern: /[a-zA-Z]+(?<!\s)$/, message: 'El comentario no puede terminar con un espacio.'},
            { pattern: /^(?!.*(.)\1{2,}).+$/, message: 'El comentario no puede contener más de dos letras iguales consecutivas.'}
          ]}
        >
          <Input.TextArea placeholder="Comparte tus comentarios o sugerencias" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enviar Feedback
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
