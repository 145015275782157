function importAllImages(r) {
  let images = {};
  r.keys().forEach(item => {
    const fileName = item.replace('./', '');
    images[fileName] = r(item);
  });
  return images;
}

const importImages = {
  importImagesFuerte: () => importAllImages(require.context('../assets/img/img-fuerte', false, /\.(png|jpe?g|svg|webp)$/)),
  importImagesIglesia: () => importAllImages(require.context('../assets/img/img-iglesia', false, /\.(png|jpe?g|svg|Webp)$/)),
  importImagesChiflon: () => importAllImages(require.context('../assets/img/img-chiflon', false, /\.(png|jpe?g|svg|Webp)$/)),
  importImagesLogos: () => importAllImages(require.context('../assets/img/img-logos', false, /\.(png|jpe?g|svg|Webp)$/)),
  importImagesParqueLota: () => importAllImages(require.context('../assets/img/img-parque-lota', false, /\.(png|jpe?g|svg|Webp)$/)),
  importMapas: () => importAllImages(require.context('../assets/img/img-mapas', false, /\.(png|jpe?g|svg|Webp)$/)),
};

export default importImages;