import React, { useEffect } from "react";
import "./FuerteColcura.css";

import { Row, Col } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import { useContraste } from "../../contexts/ContrasteContext";


import Card from "react-bootstrap/Card";
import { Fade } from "react-awesome-reveal";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import ReactAudioPlayer from "react-audio-player";
import ImageWithSpinner from "../../components/ImageWithSpinner/ImageWithSpinner"

//import audio1 from "../../assets/audio/audio-fuerte-colcura/fuerte-intervencion-1.mp3";
//import audio2 from "../../assets/audio/audio-fuerte-colcura/fuerte-historia-1.mp3";

import importImages from "../../utils/importImages";

import mapFuerte from "../../assets/pdf/plano_fuerte_colcura.pdf";
import { Carousel } from "react-bootstrap";
// Translations
import { useTranslation } from "react-i18next";
import SEO from "../../components/Seo/SEO";

const imagesFuerte = importImages.importImagesFuerte();

function ModalIntervention(props) {
  const [t] = useTranslation("global");
  const { contrasteActivado } = useContraste();

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={contrasteActivado ? "modal-alto-contraste" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("fortPage.intervention-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "auto", overflow: "auto" }}>
        <Fade triggerOnce direction="up" easing="ease-in-out">
          <Card className="card-noeffect">
            <ImageWithSpinner className="image-main-modal" variant="top" src={imagesFuerte[`fuerte-0077.webp`]} />
            <div className="mb-2 text-muted text-center">
              {t("fortPage.intervention-article.figure-1")}
            </div>
            <Card.Body>
              <Card.Title className="text-center">
                {t("fortPage.intervention-article.title")}
              </Card.Title>
              <div>
                {t("fortPage.intervention-article.decree-1")}
                <a
                  href="https://www.monumentos.gob.cl/servicios/decretos/803_1977"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("fortPage.intervention-article.decree-2")}
                </a>
              </div>
              <div>
                {t("fortPage.intervention-article.category")}
              </div>
              <Card.Subtitle className="mb-2 text-muted">
                {t("fortPage.intervention-article.subtitle-1")}
              </Card.Subtitle>
              <div>{t("fortPage.intervention-article.owner")}</div>
              <Card.Subtitle className="mb-2 text-muted">
                {t("fortPage.intervention-article.subtitle-2")}
              </Card.Subtitle>
              <div>
                <ListGroup>
                  <ListGroup.Item>
                    {t("fortPage.intervention-article.list-1")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("fortPage.intervention-article.list-2")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("fortPage.intervention-article.list-3")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("fortPage.intervention-article.list-4")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("fortPage.intervention-article.list-5")}
                    <a href={mapFuerte} target="_blank" rel="noopener noreferrer">
                      {t("fortPage.intervention-article.link-1")}
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <div className="d-flex justify-content-center mb-3">
                <ReactAudioPlayer src={t("audio.fuerte-intervencion")} controls />
              </div>
              <Card.Subtitle className="mb-2 text-muted">
                {t("fortPage.intervention-article.subtitle-3")}
              </Card.Subtitle>
              <div>
                {t("fortPage.intervention-article.paraph-1")}
              </div>
              <div>
                {t("fortPage.intervention-article.paraph-2")}
              </div>
              <div>
                {t("fortPage.intervention-article.paraph-3")}
              </div>
              <div>
                {t("fortPage.intervention-article.paraph-4")}
              </div>
              <div>
                {t("fortPage.intervention-article.paraph-5")}
              </div>
              <div>
                {t("fortPage.intervention-article.paraph-6")}
              </div>
            </Card.Body>
          </Card>
        </Fade>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="xl"
          variant="outline-primary"
          className="m-1"
          onClick={props.onHide}
        >
          {t("fortPage.history-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}


function ModalHistory(props) {
  const [t] = useTranslation("global");
  const { contrasteActivado } = useContraste();

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={contrasteActivado ? "modal-alto-contraste" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("fortPage.history-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fade triggerOnce direction="up" easing="ease-in-out">
          <Card className="card-noeffect">
            <ImageWithSpinner className="image-main-modal" variant="top" src={imagesFuerte[`fuerte-0063.webp`]} />
            <div className="mb-2 text-muted text-center">
              {t("fortPage.history-article.figure-1")}
            </div>
            <Card.Body>
              <div className="d-flex justify-content-center mb-3">
                <ReactAudioPlayer src={t("audio.fuerte-historia")} controls />
              </div>
              <Card.Title className="text-center">
                {t("fortPage.history-article.title")}
              </Card.Title>
              <div>
                <Row>
                  <Col xs={12} md={12} lg={8}>
                    <div>
                      {t("fortPage.history-article.paraph-1")}
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={4}>
                    <ImageWithSpinner
                      fluid
                      variant="top"
                      src={imagesFuerte[`fuerte-0067.webp`]}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                  <Col xs={12} md={12} lg={4}>
                    <div>
                      <ImageWithSpinner
                        fluid
                        variant="top"
                        src={imagesFuerte[`fuerte-0068.webp`]}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={8}>
                    <div>
                      {t("fortPage.history-article.paraph-2")}
                    </div>
                  </Col>
                </Row>
              </div>
              <div>{t("fortPage.history-article.paraph-3")}</div>
              <Row>
                <Col xs={12} md={12} lg={4} className="mb-1">
                  <div>
                    <ImageWithSpinner
                      fluid
                      variant="top"
                      src={imagesFuerte[`fuerte-0012.webp`]}
                    />
                  </div>
                </Col>

                <Col xs={12} md={12} lg={4} className="mb-1">
                  <div>
                    <ImageWithSpinner
                      fluid
                      variant="top"
                      src={imagesFuerte[`fuerte-0014.webp`]}
                    />
                  </div>
                </Col>

                <Col xs={12} md={12} lg={4} className="mb-1">
                  <div>
                    <ImageWithSpinner
                      fluid
                      variant="top"
                      src={imagesFuerte[`fuerte-0065.webp`]}
                    />
                  </div>
                </Col>

                <Col xs={12} md={12} lg={4} className="mb-1">
                  <div>
                    <ImageWithSpinner
                      fluid
                      variant="top"
                      src={imagesFuerte[`fuerte-0025.webp`]}
                    />
                  </div>
                </Col>

                <Col xs={12} md={12} lg={4} className="mb-1">
                  <div>
                    <ImageWithSpinner
                      fluid
                      variant="top"
                      src={imagesFuerte[`fuerte-0028.webp`]}
                    />
                  </div>
                </Col>

                <Col xs={12} md={12} lg={4} className="mb-1">
                  <div>
                    <ImageWithSpinner
                      fluid
                      variant="top"
                      src={imagesFuerte[`fuerte-0038.webp`]}
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Fade>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="xl"
          variant="outline-primary"
          className="m-1"
          onClick={props.onHide}
        >
          {t("fortPage.history-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function FuerteColcura(props) {
  const [t] = useTranslation("global");
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <SEO
        title={t("fortPage.title1")}
        description={t("fortPage.description")}
        name="CEIA LOTA"
        type={t("fortPage.heritage")}
      />
      <Row>
        <Col xs={12} md={12}>
          <Row className="mb-1">
            <Col>
              <div className="full-width">
                <Carousel fade interval={5000}>
                  {[76, 41, 68].map((num) => (
                    <Carousel.Item
                      key={num}
                      className="carousel-item-first"
                    >
                      <ImageWithSpinner
                        className="carousel-img-first d-block w-100 object-cover object-center"
                        src={
                          imagesFuerte[
                          `fuerte-${num.toString().padStart(4, "0")}.webp`
                          ]
                        }
                        alt={`Slide ${num}`}
                      />
                      <Carousel.Caption>
                        <h1>{t("fortPage.colcura-fort")}</h1>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6} className="mb-1">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <ImageWithSpinner
                    className="img-card"
                    variant="top"
                    src={imagesFuerte[`fuerte-0077.webp`]}
                  />
                  <Card.Text className="mb-2 text-muted">
                    {t("fortPage.intervention-article.figure-1")}
                  </Card.Text>
                  <Card.Body>
                    <Card.Title>
                      {t("fortPage.intervention-article.title")}
                    </Card.Title>
                    <Card.Text>
                      {t("fortPage.intervention-article.decree-1")}
                      <a
                        href="https://www.monumentos.gob.cl/servicios/decretos/373_2009"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("fortPage.intervention-article.decree-2")}
                      </a>
                    </Card.Text>
                    <Card.Text>
                      {t("fortPage.intervention-article.category")}
                    </Card.Text>
                    <Card.Subtitle className="mb-2 text-muted">
                      {t("fortPage.intervention-article.subtitle-1")}
                    </Card.Subtitle>
                    <Card.Text>
                      {t("fortPage.intervention-article.owner")}
                    </Card.Text>
                    <Button
                      variant="outline-primary"
                      onClick={() => setModalShow1(true)}
                    >
                      {t("fortPage.intervention-article.view-more")}
                    </Button>
                    <ModalIntervention
                      show={modalShow1}
                      onHide={() => setModalShow1(false)}

                    />
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            <Col md={12} lg={6} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <ImageWithSpinner
                    className="img-card"
                    variant="top"
                    src={imagesFuerte[`fuerte-0076.webp`]}
                  />
                  <Card.Text className="mb-2 text-muted">
                    {t("fortPage.history-article.figure-1")}
                  </Card.Text>
                  <Card.Body>
                    <Card.Title>
                      {t("fortPage.history-article.title")}
                    </Card.Title>
                    <Card.Text style={{ textAlign: "justify" }}>
                      {t("fortPage.history-article.paraph-preview")}
                    </Card.Text>

                    <Button
                      variant="outline-primary"
                      onClick={() => setModalShow2(true)}
                    >
                      {t("fortPage.history-article.view-more")}
                    </Button>
                    <ModalHistory
                      show={modalShow2}
                      onHide={() => setModalShow2(false)}

                    />
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
          </Row>
        </Col>
      </Row>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30738.414927457958!2d-73.17680650418035!3d-37.095481929458245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9669ea0b6569be91%3A0x8cde684cd7547e87!2sFuerte%20de%20Colcura!5e0!3m2!1ses-419!2scl!4v1715557751535!5m2!1ses-419!2scl&quot"
        style={{ border: 0 }}
        title="Ubicación de Fuerte Colcura"
        width="100%"
        height="400"
        allowFullScreen={true}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default FuerteColcura;
