import React, { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ImageWithSpinner.css';

const ImageWithSpinner = ({ src, alt, className }) => {
  const [loading, setLoading] = useState(true);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const img = imgRef.current;

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.2,
    });

    if (img) {
      observer.observe(img);
    }

    return () => {
      if (img) {
        observer.unobserve(img);
      }
    };
  }, []);

  useEffect(() => {
    if (isIntersecting && imgRef.current) {
      const imgElement = imgRef.current;
      if (!imgElement.complete) {
        imgElement.onload = () => {
          setLoading(false);
        };
      } else {
        setLoading(false);
      }
    }
  }, [isIntersecting]);

  return (
    <div className="image-container" style={{ position: 'relative' }}>
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(200, 200, 200, 0.7)',
            zIndex: 1,
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <img
        ref={imgRef}
        className={className}
        src={isIntersecting ? src : ''}
        alt={alt}
        style={{
          opacity: loading ? 0 : 1,
          maxWidth: '100%',
          height: 'auto',
          transition: 'opacity 0.3s ease-in-out',
        }}
      />
    </div>
  );
};

export default ImageWithSpinner;
