import React  from 'react';
import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home/Home";
import IglesiaSanMatias from "../pages/IglesiaSanMatias/IglesiaSanMatias";
import ParqueLota from "../pages/ParqueLota/ParqueLota";
import MinaElChiflon from "../pages/MinaElChiflon/MinaElChiflon";
import FuerteColcura from "../pages/FuerteColcura/FuerteColcura";
import GotaDeLeche from "../pages/GotaDeLeche/GotaDeLeche";
import { NotFound } from "../pages/NotFound/NotFound";
import LayoutPublic from "../LayoutPublic/LayoutPublic";
import { ContrasteProvider } from '../contexts/ContrasteContext';

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ContrasteProvider>
        <LayoutPublic />
      </ContrasteProvider>
    ),
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/iglesia-san-matias",
        element: <IglesiaSanMatias />,
        
      },
      {
        path: "/mina-el-chiflon",
        element: <MinaElChiflon />,
      },
      {
        path: "/parque-isidora-cousino",
        element: <ParqueLota />,
      },
      {
        path: "/fuerte-colcura",
        element: <FuerteColcura />,
      },
      {
        path: "/gota-de-leche",
        element: <GotaDeLeche />,
      },
    ],
  },
]);
