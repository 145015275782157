import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
// Rutas
import { router } from './router';
import { RouterProvider } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
// Traducciones
import { I18nextProvider } from 'react-i18next';
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import i18next from 'i18next';
import global_es from './translations/es/global.json';
import global_en from './translations/en/global.json';



i18next.use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        global: global_es
      },
      en: {
        global: global_en
      },
    },
    fallbackLng: "es", // use en if detected lng is not available
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18next}>
      <RouterProvider router={router} />
    </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
