import React from "react";
import { useState, useEffect } from "react";
import { Card, Col, Container, Row, Carousel } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import ImageWithSpinner from "../../components/ImageWithSpinner/ImageWithSpinner";
import "./Home.css";
import { useTranslation } from "react-i18next";
import SEO from "../../components/Seo/SEO";

import importImages from "../../utils/importImages";
// import imgChiflon1 from "../../assets/img/chiflon-1.jpg";
// import imgChurch1 from "../../assets/img/iglesia-3.jpg";
// import imgPark1 from "../../assets/img/parque-lota-1.jpg";
// import imgFort1 from "../../assets/img/fuerte-0076.jpg";
const imagesChiflon = importImages.importImagesChiflon();
const ImagesIglesia = importImages.importImagesIglesia();
const ImagesParque = importImages.importImagesParqueLota();
const ImagesFuerte = importImages.importImagesFuerte();

function CarouselCaption() {
  const [t] = useTranslation("global");
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    // Después de que la animación se haya ejecutado, establece 'animate' en false
    const timer = setTimeout(() => {
      setAnimate(false);
    }, 1000); // Asume que la animación dura 500ms

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Carousel.Caption
        className={`carousel-caption-1 ${animate ? "animate" : ""}`}
      >
        <h3 className="carousel-caption-text">{t("home.title2")}</h3>
        <p className="carousel-caption-text">{t("home.paraph1")}</p>
        <p className="carousel-caption-text">
          {t("home.paraph2")}{" "}
          <Link
            to="https://ceialota.cl"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.ceialota.cl
          </Link>
          ).
        </p>
        <h3 className="carousel-caption-text">{t("home.title3")}</h3>
        <p className="carousel-caption-text">{t("home.paraph3")}</p>
        <p className="carousel-caption-text">{t("home.paraph4")}</p>
      </Carousel.Caption>
    </>
  );
}

function Home() {
  const [t] = useTranslation("global");
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Container fluid className="p-0">
      <SEO
        title={t("home.title1")}
        description={t("home.description")}
        name="CEIA LOTA"
        type={t("home.heritage")}
      />
      <div className="full-width">
        <CarouselCaption />
        <Carousel fade interval={5000} className="home-carousel">
          <Carousel.Item className="home-carousel-item">
            <ImageWithSpinner
              className="home-carousel-img w-100"
              src={imagesChiflon["chiflon-1.Webp"]}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item className="home-carousel-item">
            <ImageWithSpinner
              className="home-carousel-img d-block w-100"
              src={ImagesParque[`parque-lota-1.Webp`]}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item className="home-carousel-item">
            <ImageWithSpinner
              className="home-carousel-img w-100"
              src={ImagesIglesia[`iglesia-3.Webp`]}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item className="home-carousel-item">
            <ImageWithSpinner
              className="home-carousel-img w-100"
              src={ImagesFuerte[`fuerte-0076.webp`]}
              alt="Fourth slide"
            />
          </Carousel.Item>
        </Carousel>
      </div>

      <Row className="mt-3">
        <Col xs={12} md={4}>
          <Card className="border-0 rounded-0 mb-3">
            <Link to="/mina-el-chiflon">
              <ImageWithSpinner
                className="card-img"
                variant="top"
                src={imagesChiflon["chiflon-1.Webp"]}
              />
            </Link>
            <Card.Title className="text-center mt-2">
              {t("home.mine")}
            </Card.Title>
            <Card.Body className="text-center">
              <Link to="/mina-el-chiflon" className="btn-like-link">
                {" "}
                {t("home.go-to")}
                {t("home.mine-min")}
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card className="border-0 rounded-0 mb-3">
            <Link to="/iglesia-san-matias">
              <ImageWithSpinner
                className="card-img"
                variant="top"
                src={ImagesIglesia[`iglesia-3.Webp`]}
              />
            </Link>
            <Card.Title className="text-center mt-2">
              {t("home.church")}
            </Card.Title>
            <Card.Body className="text-center">
              <Link to="/iglesia-san-matias" className="btn-like-link">
                {" "}
                {t("home.go-to")}
                {t("home.church-min")}
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card className="border-0 rounded-0 mb-3">
            <Link to="/parque-isidora-cousino">
              <ImageWithSpinner
                className="card-img"
                variant="top"
                src={ImagesParque[`parque-lota-1.Webp`]}
              />
            </Link>
            <Card.Title className="text-center mt-2">
              {t("home.park")}
            </Card.Title>
            <Card.Body className="text-center">
              <Link to="/parque-isidora-cousino" className="btn-like-link">
                {" "}
                {t("home.go-to")}
                {t("home.park-min")}
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={4}>
          <Card className="border-0 rounded-0 mb-3">
            <Link to="/fuerte-colcura">
              <ImageWithSpinner
                className="card-img"
                variant="top"
                src={ImagesFuerte[`fuerte-0076.webp`]}
              />
            </Link>
            <Card.Title className="text-center mt-2">
              {t("home.fort")}
            </Card.Title>
            <Card.Body className="text-center">
              <Link to="/fuerte-colcura" className="btn-like-link">
                {" "}
                {t("home.go-to")}
                {t("home.fort-min")}
              </Link>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={4}>
          <Card className="border-0 rounded-0 mb-3">
            <Link to="/gota-de-leche">
              <ImageWithSpinner
                className="card-img"
                variant="top"
                src={ImagesParque[``]}
              />
            </Link>
            <Card.Title className="text-center mt-2">
              {t("home.gota")}
            </Card.Title>
            <Card.Body className="text-center">
              <Link to="/gota-de-leche" className="btn-like-link">
                {" "}
                {t("home.go-to")}
                {t("home.gota-min")}
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
