import React, { useEffect } from "react";
import "./IglesiaSanMatias.css";
import { useContraste } from "../../contexts/ContrasteContext";

import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { Fade } from "react-awesome-reveal";
import Button from "react-bootstrap/Button";

import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import ImageWithSpinner from "../../components/ImageWithSpinner/ImageWithSpinner";

//import audio1 from "../../assets/audio/audio-iglesia/iglesia-intervencion-1.mp3";
import importImages from "../../utils/importImages";
// import imgChurch1 from "../../assets/img/iglesia-3.Webp";
// import imgChurch2 from "../../assets/img/iglesia-2.Webp";
// import imgChurch3 from "../../assets/img/iglesia-1.Webp";
// import imgChurch4 from "../../assets/img/iglesia-visita-1.Webp";
// import imgChurch5 from "../../assets/img/iglesia-visita-2.Webp";
// import imgChurch6 from "../../assets/img/iglesia-visita-3.Webp";
// import imgChurch7 from "../../assets/img/iglesia-visita-4.Webp";
// import imgChurch8 from "../../assets/img/iglesia-visita-5.Webp";
// import imgChurch9 from "../../assets/img/iglesia-vitrales-1.Webp";

import vidChurch1 from "../../assets/videos/iglesia-video-1.mp4";

import mapChurch from "../../assets/pdf/plano_iglesia_san_matias.pdf";
import { Carousel } from "react-bootstrap";
// Translations
import { useTranslation } from "react-i18next";
import SEO from "../../components/Seo/SEO";

const ImagesIglesia = importImages.importImagesIglesia();

function ModalInterview(props) {
  const [t] = useTranslation("global");
  const { contrasteActivado } = useContraste();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={contrasteActivado ? "modal-alto-contraste" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("churchPage.interview-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fade triggerOnce direction="up" easing="ease-in-out">
          <Card className="card-noeffect">
            <ImageWithSpinner
              className="image-main-modal"
              variant="top"
              src={ImagesIglesia[`iglesia-2.Webp`]}
            />
            <Card.Text className="mb-2 text-muted text-center">
              {t("churchPage.interview-article.figure-1")}
            </Card.Text>
            <Card.Body>
              <Card.Title className="text-center">
                {t("churchPage.interview-article.title")}
              </Card.Title>
              <div>
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/XiWUNNTe2O8"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
                <Card.Subtitle className="mb-2 text-center text-muted">
                  {t("churchPage.interview-article.subtitle-1")}
                </Card.Subtitle>
              </div>
              <div>
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/xITVCU-5lmk"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
                <Card.Subtitle className="mb-2 text-center text-muted">
                  {t("churchPage.interview-article.subtitle-2")}
                </Card.Subtitle>
              </div>
            </Card.Body>
          </Card>
        </Fade>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          {t("churchPage.interview-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ModalIntervention(props) {
  const [t] = useTranslation("global");
  const { contrasteActivado } = useContraste();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={contrasteActivado ? "modal-alto-contraste" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("churchPage.intervention-article.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fade triggerOnce direction="up" easing="ease-in-out">
          <Card className="card-noeffect">
            <ImageWithSpinner
              className="image-main-modal"
              variant="top"
              src={ImagesIglesia[`iglesia-1.Webp`]}
            />
            <Card.Text className="mb-2 text-muted text-center">
              {t("churchPage.intervention-article.figure-1")}
            </Card.Text>
            <Card.Body>
              <Card.Title className="text-center">
                {t("churchPage.intervention-article.title")}
              </Card.Title>
              <div>
                {t("churchPage.intervention-article.decree-1")}
                <a
                  href="https://www.monumentos.gob.cl/servicios/decretos/232_2014"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("churchPage.intervention-article.decree-2")}
                </a>
              </div>
              <div>{t("churchPage.intervention-article.category")}</div>
              <Card.Subtitle className="mb-2 text-muted">
                {t("churchPage.intervention-article.subtitle-1")}
              </Card.Subtitle>
              <div>{t("churchPage.intervention-article.description")}</div>
              <Card.Subtitle className="mb-2 text-muted">
                {t("churchPage.intervention-article.subtitle-2")}
              </Card.Subtitle>
              <div>
                <ListGroup>
                  <ListGroup.Item>
                    {t("churchPage.intervention-article.list-1")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("churchPage.intervention-article.list-2")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("churchPage.intervention-article.list-3")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("churchPage.intervention-article.list-4")}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t("churchPage.intervention-article.list-5")}
                    <a
                      href={mapChurch}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("churchPage.intervention-article.link-1")}
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <div className="d-flex justify-content-center mb-3">
                <ReactAudioPlayer src={t("audio.iglesia-intervencion")} controls />
              </div>
              <Card.Subtitle className="mb-2 text-muted">
                {t("churchPage.intervention-article.subtitle-3")}
              </Card.Subtitle>
              <div>{t("churchPage.intervention-article.paraph-1")}</div>
              <div>{t("churchPage.intervention-article.paraph-2")}</div>
              <div>{t("churchPage.intervention-article.paraph-3")}</div>
              <div>{t("churchPage.intervention-article.paraph-4")}</div>
              <div>{t("churchPage.intervention-article.paraph-5")}</div>
              <div>{t("churchPage.intervention-article.paraph-6")}</div>
            </Card.Body>
          </Card>
        </Fade>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onHide}>
          {t("churchPage.intervention-article.close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function IglesiaSanMatias() {
  const [t] = useTranslation("global");
  const [modalShow1, setModalShow1] = React.useState(false);
  const [modalShow3, setModalShow3] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <SEO
        title={t("churchPage.title1")}
        description={t("churchPage.description")}
        name="CEIA LOTA"
        type={t("churchPage.heritage")}
      />
      <Row>
        <Col xs={12} md={12}>
          <Row className="mb-1">
            <Col>
              <div className="full-width">
                <Carousel fade interval={5000}>
                  {[3, 2, 1].map((index) => (
                    <Carousel.Item key={index} className="carousel-item-first">
                      <ImageWithSpinner
                        className="carousel-img-first h-100 w-100 object-cover object-center"
                        src={ImagesIglesia[`iglesia-${index}.Webp`]}
                        alt={`Slide ${index}`}
                      />
                      <Carousel.Caption>
                        <h1>{t("churchPage.title")}</h1>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={4} className="mb-1">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <ImageWithSpinner
                    className="img-card"
                    variant="top"
                    src={ImagesIglesia[`iglesia-3.Webp`]}
                  />
                  <Card.Text className="mb-2 text-muted">
                    {t("churchPage.intervention-article.figure-1")}
                  </Card.Text>
                  <Card.Body>
                    <Card.Title>
                      {t("churchPage.intervention-article.title")}
                    </Card.Title>
                    <Card.Text>
                      {t("churchPage.intervention-article.decree-1")}
                      <a
                        href="https://www.monumentos.gob.cl/servicios/decretos/232_2014"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("churchPage.intervention-article.decree-2")}
                      </a>
                    </Card.Text>
                    <Card.Text>
                      {t("churchPage.intervention-article.category")}
                    </Card.Text>
                    <Card.Subtitle className="mb-2 text-muted">
                      {t("churchPage.intervention-article.subtitle-1")}
                    </Card.Subtitle>
                    <Card.Text>
                      {t("churchPage.intervention-article.description")}
                    </Card.Text>
                    <Button
                      variant="outline-primary"
                      onClick={() => setModalShow1(true)}
                    >
                      {t("churchPage.intervention-article.view-more")}
                    </Button>
                    <ModalIntervention
                      show={modalShow1}
                      onHide={() => setModalShow1(false)}
                    />
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            <Col md={12} lg={4} className="mb-1">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <ImageWithSpinner
                    className="img-card"
                    variant="top"
                    src={ImagesIglesia[`iglesia-2.Webp`]}
                  />
                  <Card.Text className="mb-2 text-muted">
                    {t("churchPage.interview-article.figure-1")}
                  </Card.Text>
                  <Card.Body>
                    <Card.Title>
                      {t("churchPage.interview-article.title")}
                    </Card.Title>

                    <Card.Text>
                      <iframe
                        width="100%"
                        height="250"
                        src="https://www.youtube.com/embed/XiWUNNTe2O8"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    </Card.Text>
                    <Card.Subtitle className="mb-2 text-muted">
                      {t("churchPage.interview-article.subtitle-1")}
                    </Card.Subtitle>

                    <Button
                      variant="outline-primary"
                      onClick={() => setModalShow3(true)}
                    >
                      {t("churchPage.interview-article.view-more")}
                    </Button>
                    <ModalInterview
                      show={modalShow3}
                      onHide={() => setModalShow3(false)}
                    />
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            <Col md={12} lg={4} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Body>
                    <Card.Title>
                      {t("churchPage.intervention-article.video-dron")}
                    </Card.Title>
                    <Carousel fade>
                      <Carousel.Item>
                        <iframe
                          width="100%"
                          height="400"
                          src="https://www.youtube.com/embed/dztEn8IsZ2k"
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                      </Carousel.Item>
                      <Carousel.Item>
                        <iframe
                          width="100%"
                          height="400"
                          src="https://www.youtube.com/embed/Ty25Xc981xE"
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                      </Carousel.Item>
                    </Carousel>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            {/* Visita pedagógica 2023 */}
            <Col md={12} lg={4} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Body>
                    <Card.Title>{t("churchPage.visit.title")}</Card.Title>
                    <Carousel fade>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="img-card"
                          src={ImagesIglesia[`iglesia-visita-1.Webp`]}
                          alt={t("churchPage.visit.visit-1")}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="img-card"
                          src={ImagesIglesia[`iglesia-visita-2.Webp`]}
                          alt={t("churchPage.visit.visit-1")}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="img-card"
                          src={ImagesIglesia[`iglesia-visita-3.Webp`]}
                          alt={t("churchPage.visit.visit-1")}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="img-card"
                          src={ImagesIglesia[`iglesia-visita-4.Webp`]}
                          alt={t("churchPage.visit.visit-1")}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="img-card"
                          src={ImagesIglesia[`iglesia-visita-5.Webp`]}
                          alt={t("churchPage.visit.visit-1")}
                        />
                      </Carousel.Item>
                    </Carousel>
                    <Card.Text>{t("churchPage.visit.visit-1")}</Card.Text>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            {/* Vitrales */}
            <Col md={12} lg={4} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Body>
                    <Card.Title>
                      {t("churchPage.stained-glass.title")}
                    </Card.Title>
                    <Carousel fade>
                      <Carousel.Item>
                        <ImageWithSpinner
                          className="img-card"
                          src={ImagesIglesia[`iglesia-vitrales-1.Webp`]}
                          alt={t("churchPage.stained-glass.image-1")}
                        />
                        <Card.Text>
                          {t("churchPage.stained-glass.image-1")}
                        </Card.Text>
                      </Carousel.Item>
                    </Carousel>
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
            {/* Tuberculosis */}
            <Col md={12} lg={4} className="mb-2">
              <Fade triggerOnce direction="up" easing="ease-in-out">
                <Card className="text-center border-0 rounded-0">
                  <Card.Body>
                    <Card.Title>{t("churchPage.videos.title")}</Card.Title>
                    <ReactPlayer
                      url={vidChurch1}
                      controls={true}
                      width="100%"
                      height="400"
                      title={t("churchPage.videos.title")}
                    />
                  </Card.Body>
                </Card>
              </Fade>
            </Col>
          </Row>
        </Col>
      </Row>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26800.669148158904!2d-73.17355106647567!3d-37.08464661245291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9669c1e97a9047f3%3A0xca2b601e9b0f1eb1!2sIglesia%20de%20San%20Mat%C3%ADas%20Ap%C3%B3stol!5e0!3m2!1ses-419!2scl!4v1715546544981!5m2!1ses-419!2scl&quot"
        style={{ border: 0 }}
        title="Ubicación de la Iglesia San Matías Apóstol"
        width="100%"
        height="400"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default IglesiaSanMatias;
